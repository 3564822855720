import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import PageHeader from "../PageHeader";

const ContactUs = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });



	return (
		<Fragment>
			<PageHeader title={"Contact Us"} />
			{isMobile === true &&
				<Box sx={{ p: 2 }}>
					<Paper elevation={2} sx={{ mb: 3 }}>
						<img src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
						     alt="Our Store"
						     style={{
								 width: '100%',
							     height: 'auto',
							     borderRadius: '4px'
						     }}
						/>
					</Paper>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box sx={{ mb: 2 }}>
								<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
									Opening Hours
								</Typography>
								<Typography variant="body2">
									Monday to Thursday: 8:00 AM - 4.30 PM
									<br />
									Friday: 8:00 AM - 1.30 PM
									<br />
									Saturday & Sunday: Closed
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box sx={{ mb: 2 }}>
								<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
									Address
								</Typography>
								<Link href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
								      target="_blank"
								      rel="noopener"
								      variant="body2"
								      sx={{ textDecoration: 'none', color: 'primary.main' }}>
									28 Great North Road, Brentwood Park, 1501, South Africa
								</Link>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box sx={{ mb: 2 }}>
								<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
									Contact Us
								</Typography>
								<Link
									href="tel:0117475200"
									variant="body2"
									sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', mb: 1 }}
								>
									<IconButton>
										<PhoneIcon fontSize="small" />
									</IconButton>
									(+27)11 747 5200
								</Link>

								<Link
									href="https://wa.link/ov1mre"
									variant="body2"
									sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', mb: 1 }}
								>
									<IconButton>
										<WhatsAppIcon fontSize="small" />
									</IconButton>
									083 654 5120
								</Link>

								<Link
									href="mailto:info@argusmotoring.co.za"
									variant="body2"
									sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
								>
									<IconButton>
										<EmailIcon fontSize="small" />
									</IconButton>
									info@argusmotoring.co.za
								</Link>
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Box sx={{ position: 'relative', overflow: 'hidden', pb: '56.25%', mb: 2 }}>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5795860208736!2d28.2970668!3d-26.1452474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9516007af0406f%3A0xccf786c2e19b2866!2sArgus%20Motor%20Company!5e0!3m2!1sen!2sza!4v1715939532944!5m2!1sen!2sza"
									width="100%"
									height="100%"
									style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
									allowFullScreen=""
									loading="lazy"
									title="Argus Location"
								></iframe>
							</Box>
						</Grid>
					</Grid>
				</Box>
			}
			{isMobile === false &&
				<Box sx={{ p: 4 }}>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<Paper elevation={4}>
								<img
									src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
									alt="Our Store"
									style={{
										width: '100%',
										height: 'auto',
										borderRadius: '8px',
									}}
								/>
							</Paper>
						</Grid>

						<Grid item xs={12} md={6}>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
											Opening Hours
										</Typography>
										<Typography variant="caption">
											Monday to Thursday: 8:00 AM - 4.30 PM
											<br />
											Friday: 8:00 AM - 1.30 PM
											<br />
											Saturday & Sunday: Closed
										</Typography>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box>
										<Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
											Address
										</Typography>
										<Link
											href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
											target="_blank"
											rel="noopener"
											variant="caption"
											sx={{ textDecoration: 'none', color: 'inherit' }}
										>
											28 Great North Road, Brentwood Park, 1501, South Africa
										</Link>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box>
										<Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
											Contact Us
										</Typography>
										<Link
											href="tel:0117475200"
											variant="caption"
											sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', mb: 2 }}
										>
											<IconButton>
												<PhoneIcon fontSize="small" />
											</IconButton>
											(+27)11 747 5200
										</Link>

										<Link
											href="https://wa.link/ov1mre"
											variant="caption"
											sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', mb: 2 }}
										>
											<IconButton>
												<WhatsAppIcon fontSize="small" />
											</IconButton>
											083 654 5120
										</Link>

										<Link
											href="mailto:info@argusmotoring.co.za"
											variant="caption"
											sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
										>
											<IconButton>
												<EmailIcon fontSize="small" />
											</IconButton>
											info@argusmotoring.co.za
										</Link>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Box sx={{ position: 'relative', overflow: 'hidden', pb: '50%' }}>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5795860208736!2d28.2970668!3d-26.1452474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9516007af0406f%3A0xccf786c2e19b2866!2sArgus%20Motor%20Company!5e0!3m2!1sen!2sza!4v1715939532944!5m2!1sen!2sza"
									width="100%"
									height="100%"
									style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
									allowFullScreen=""
									loading="lazy"
									title="Argus Location"
								></iframe>
							</Box>
						</Grid>
					</Grid>
				</Box>
			}
		</Fragment>
		// <section className="argus-min-height">
		// 	<article className="argus-title-background ">
		// 		<div className="argus-title">Contact us</div>
		// 	</article>
		// 	<article className="container">
		// 		<section className="row">
		// 			<article
		// 				className="col-lg-6 col-md-12"
		// 				style={{margin: 0, padding: 0}}
		// 			>
		// 				<Image
		// 					src="https://argusweb.azureedge.net/argus-web/contact-us/contact-us-storefront.jpg"
		// 					size="huge"
		// 				/>
		// 			</article>
		//
		// 			<article
		// 				className="col-lg-6 col-md-12 contact_info_container "
		// 				style={{margin: 0, padding: 0}}
		// 			>
		// 				<section className="contact_text_container">
		// 					<h3 className="underline">Opening Hours</h3>
		// 				</section>
		//
		// 				<section className="contact_text_container">
		// 					<p className=" opening_hours_text">
		// 						Monday to Thursday: 8am-4.30pm
		// 					</p>
		// 					<p className="opening_hours_text">Friday: 8am-1.30pm</p>
		// 					<p className="opening_hours_text">Saturday & Sunday: Closed</p>
		// 				</section>
		//
		// 				<section className="contact_text_container">
		// 					<h3 className="underline">Our address</h3>
		// 					<a
		// 						href="https://goo.gl/maps/RbgTFmVshrD2WJYu8"
		// 						target="_blank"
		// 						rel="noreferrer"
		// 					>
		// 						28 Great North Road, Brentwood Park, 1501, South Africa
		// 					</a>
		// 				</section>
		// 				<section className="contact_text_container">
		// 					<Icon name="phone"/>
		// 					<a href="tel:0117475200">(+27)11 747 5200</a>
		// 				</section>
		// 				<section className="contact_text_container">
		// 					<Icon name="whatsapp"/>
		// 					<a
		// 						href="https://wa.link/ov1mre "
		// 						target="_blank"
		// 						className="b1"
		// 						rel="noreferrer"
		// 					>
		// 						083 645 5120
		// 					</a>
		// 				</section>
		// 				<section className="contact_text_container">
		// 					<Icon name="at"/>
		// 					<a href="mailto:info@argusmotoring.co.za">
		// 						info@argusmotoring.co.za
		// 					</a>
		// 				</section>
		// 			</article>
		// 		</section>
		// 	</article>
		//
		// 	<iframe
		// 		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5795860208736!2d28.2970668!3d-26.1452474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9516007af0406f%3A0xccf786c2e19b2866!2sArgus%20Motor%20Company!5e0!3m2!1sen!2sza!4v1715939532944!5m2!1sen!2sza"
		// 		width="100%"
		// 		height="250"
		// 		style={{border: 0, margin: 0, padding: 0}}
		// 		allowFullScreen=""
		// 		loading="lazy"
		// 		referrerPolicy="no-referrer-when-downgrade"
		// 	></iframe>
		// </section>
	);
};
export default ContactUs;
